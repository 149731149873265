<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header class="info--text">
                Configurações - Mapa
                <template v-slot:actions>
                    <v-icon color="info">edit</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <PointForm :itemToEdit="input" @save="send" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import PointForm from "@/pages/Point/form";

export default {
    directives: { mask },

    components: { PointForm },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            input: null,
            valid: true,
            sending: false,
        };
    },

    created() {
        this.input = { ...this.data };
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .put("api/v2/point", this.input.id, this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Dados salvo com sucesso.");
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao salvar dados.");
                    });
            }
        },
    },
};
</script>